import {
  Box,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';

import {
  BinaryOperation,
  CustomColumn as GqlCustomColumn,
  CustomColumnCondition,
  CustomColumnConditionVariable,
  OperationType,
  PipelineFilter,
} from '@revelio/data-access';
import { find } from 'lodash';
import {
  CustomColumn,
  DatasetDateFilter,
  DatasetFilter,
  ElseDefaultCategoryCase,
  isCategoryCase,
} from '../deliverables.model';
import {
  Condition,
  isMultiGranularitySelectionListVariable,
  isSelectionListValue,
  isStringVariable,
  MultiGranularitySelectionListVariables,
  SimpleFormTreeItem,
} from '../columns/dataset-conditions/conditions.model';
import { CategoryCase } from '../columns/custom/custom.model';
import {
  NUMERIC_OPERATION_SELECT_OPTIONS,
  NumericOperationSelectOption,
  SELECTION_LIST_OPERATION_SELECT_OPTIONS,
  SelectionListOperationSelectOption,
  STRING_OPERATION_SELECT_OPTIONS,
  StringOperationSelectOption,
  VARIABLE_SELECT_OPTIONS,
} from '../columns/dataset-conditions/dataset-conditions-form-helpers';
import { DetailsSummaryTrigger, SummaryPopover } from './confirmation.shared';
import { SelectionCategoryLabelOverrides } from '@revelio/filtering';
import { labelForPipelineDateRangeFilter } from '../columns/filters/dataset-date-range/dataset-date-range-helpers';

export const CustomColumnSummary = ({
  customColumns,
}: {
  customColumns: (CustomColumn | GqlCustomColumn | null)[] | null | undefined;
}) => {
  if (!customColumns || !customColumns.length) {
    return <>None</>;
  }

  return (
    <>
      {(customColumns as (CustomColumn | GqlCustomColumn)[]).map(
        (customColumn, i) => {
          const isLast = i === customColumns.length - 1;
          return (
            <SummaryPopover
              trigger={
                <Box as="span" mr="1">
                  <DetailsSummaryTrigger text={customColumn.name} />
                  {!isLast && ','}
                </Box>
              }
            >
              <Table size="sm" variant="unstyled" w="max-content">
                <Tbody>
                  {(
                    customColumn.step as (
                      | CategoryCase
                      | ElseDefaultCategoryCase
                    )[]
                  )?.map((step, stepIndex) => {
                    if (isCategoryCase(step)) {
                      return (
                        <Tr key={`customColumnSummary-${stepIndex}`}>
                          <Td minW="60px" px="0">
                            <Text mr="2" textColor="pink.700">
                              {stepIndex === 0 ? 'If' : 'Else if '}
                            </Text>
                          </Td>

                          <Td pl="0">
                            <Flex alignItems="center">
                              <Box
                                borderY="0"
                                borderX="1px"
                                borderRadius="15px"
                                p="1"
                                borderColor="pink.700"
                                minW="200px"
                              >
                                {step.case.conditions.map(
                                  (condition, conditionIndex) => (
                                    <ConditionDetail
                                      condition={condition}
                                      binary={step.case.binary}
                                      isLast={
                                        step.case.conditions.length - 1 !==
                                        conditionIndex
                                      }
                                    />
                                  )
                                )}
                              </Box>
                              <Text ml="1" textColor="pink.700">
                                Then
                              </Text>
                            </Flex>
                          </Td>

                          <Td px="0" minW="70px">
                            <Text ml="2">
                              <Text as="b" textColor="green.500">
                                "{step.then}"
                              </Text>
                            </Text>
                          </Td>
                        </Tr>
                      );
                    }

                    return (
                      <Tr key={`customColumnSummary-${stepIndex}`}>
                        <Td px="0" textColor="pink.700">
                          Else
                        </Td>
                        <Td></Td>
                        <Td as="b" textColor="green.500">
                          "{step?.then}"
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </SummaryPopover>
          );
        }
      )}
    </>
  );
};

export const FiltersSummary = ({
  filters,
  dateFilter,
}: {
  filters: DatasetFilter[] | (PipelineFilter | null)[] | null | undefined;
  dateFilter: DatasetDateFilter | undefined;
}) => {
  if (!dateFilter && (!filters || !filters.length)) {
    return <>None</>;
  }

  return (
    <>
      {dateFilter && (
        <SummaryPopover
          trigger={
            <Box as="span" mr="1">
              <DetailsSummaryTrigger text={'Date Range'} />
              {filters && ','}
            </Box>
          }
        >
          <Box as="span">{labelForPipelineDateRangeFilter(dateFilter)}</Box>
        </SummaryPopover>
      )}
      {((filters as (DatasetFilter | PipelineFilter)[]) ?? []).map(
        (filter, i) => {
          const isLast = filters && i === filters.length - 1;
          return (
            <SummaryPopover
              trigger={
                <Box as="span" mr="1">
                  <DetailsSummaryTrigger text={filter.name} />
                  {!isLast && ','}
                </Box>
              }
            >
              {filter.conditions?.map((condition, conditionIndex) => (
                <ConditionDetail
                  condition={condition}
                  binary={filter.binary}
                  isLast={
                    (filter.conditions as Condition[]).length - 1 !==
                    conditionIndex
                  }
                />
              ))}
            </SummaryPopover>
          );
        }
      )}
    </>
  );
};

const getDeprecatedOperationTypes = (
  variable:
    | CustomColumnConditionVariable
    | MultiGranularitySelectionListVariables
) => [
  {
    label: isStringVariable(variable) ? 'Contains any of' : 'In set of',
    value: OperationType.ContainsAnyOf,
  },
  {
    label: isStringVariable(variable)
      ? 'Does not contain any of'
      : 'Not in set of',
    value: OperationType.DoesNotContainAnyOf,
  },
];

export const transformDeprecatedOperationTypes = (
  operation?: OperationType
): OperationType => {
  if (operation === OperationType.ContainsAnyOf) {
    return OperationType.ContainsAtLeastOne;
  }

  if (operation === OperationType.DoesNotContainAnyOf) {
    return OperationType.DoesNotContainAll;
  }

  return operation as OperationType;
};

const ConditionDetail = ({
  condition,
  binary,
  isLast,
}: {
  condition: Condition | CustomColumnCondition;
  binary: BinaryOperation | null | undefined;
  isLast: boolean;
}) => (
  <>
    <Flex minW="200px" justifyContent="flex-start">
      <Text as="span" textColor="blue.600" minW="70px">
        {find(VARIABLE_SELECT_OPTIONS, { value: condition.variable })?.label}
      </Text>{' '}
      <Text as="span" textColor="yellow.600" minW="80px">
        {
          (
            find(
              [
                ...NUMERIC_OPERATION_SELECT_OPTIONS,
                // string and selection list operations have differently labeled options for the same values
                ...(isStringVariable(condition.variable)
                  ? STRING_OPERATION_SELECT_OPTIONS
                  : SELECTION_LIST_OPERATION_SELECT_OPTIONS),
                getDeprecatedOperationTypes(condition.variable),
              ],
              { value: condition.operation }
            ) as
              | NumericOperationSelectOption
              | StringOperationSelectOption
              | SelectionListOperationSelectOption
          ).label
        }
      </Text>{' '}
      <Text maxW={200}>
        {condition.value &&
        isSelectionListValue(
          condition.value as number[] | string[] | SimpleFormTreeItem[]
        )
          ? (condition.value as SimpleFormTreeItem[])
              .map((i) => {
                const multiGranularitySelectionListLabel =
                  isMultiGranularitySelectionListVariable(condition.variable)
                    ? `(${SelectionCategoryLabelOverrides[i.selectionListId]})`
                    : '';
                return `${i.item?.label} ${multiGranularitySelectionListLabel}`;
              })
              .join(', ')
          : condition.value?.join(', ')}
      </Text>
    </Flex>

    {isLast && (
      <Flex marginY="2" alignItems="center">
        <Divider orientation="horizontal" borderBottomWidth="2px" m="0" />
        <Text px="1">{binary}</Text>
        <Divider orientation="horizontal" borderBottomWidth="2px" m="0" />
      </Flex>
    )}
  </>
);
