/* eslint-disable prefer-const */
import { FilterSets } from '../data-api/data-api.model';
import {
  FilteringInitConfig,
  FilterLabelOverrides,
  FilterList,
  FilterOrSubfilterName,
  FilterParameterKeys,
  FilterSetLabelOverrides,
  LocalSelectionCategories,
  OperatorOptionsType,
  OPValues,
  OtherFilterNames,
  QueryParameterKeys,
  RoleSelectionCategories,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
} from './filters.model';

export let API_ROOT = '';
export let GO_API_ROOT = '';
export let COMPANY_MAPPER_URL = '';

export function initFiltering(config: FilteringInitConfig) {
  API_ROOT = config.apiRoot;
  GO_API_ROOT = config.goApiRoot;
  COMPANY_MAPPER_URL = config.companyMapperUrl;
}

export const PYTHON_COMPANY_SELECTION_ID = 'python_company';

export const FiltersUsedInTabs = [
  LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
  LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
  LocalSelectionCategories.INFLOW_OR_OUTFLOW,
  LocalSelectionCategories.TAXONOMY_TAB_VIEWS,
];

export const FiltersUsedInSelectSubfilters = [
  LocalSelectionCategories.N_ITEMS_SANKEY,
];

export const DateMonthFilterNames = [
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  OtherFilterNames.LAST_MONTH,
  OtherFilterNames.LAST_START_DATE,
  SelectionCategories.START_TIME,
  SelectionCategories.END_TIME,
];

export const REPORT_COMPANY_FILTERS = [SelectionCategories.COMPANY_REPORT];

export const INDUSTRY_AND_COMPANY_FILTERS = [
  SelectionCategories.COMPANY,
  SelectionCategories.INDUSTRY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.RICS_K400,
];

export const ALL_ROLE_FILTERS: RoleSelectionCategories[] = [
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K7,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K300,
  SelectionCategories.ROLE_K500,
  SelectionCategories.ROLE_K1250,
  SelectionCategories.ROLE_K1500,
];

export const ROLE_GRANULARITY_FILTERS = [
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
];

export const RICS_INDUSTRY_FILTERS = [
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
];

export const RICS_INDUSTRY_AND_COMPANY_FILTERS = [
  SelectionCategories.COMPANY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
];

export const RICS_INDUSTRY_AND_COMPANY_FILTERS_SORTED = [
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.COMPANY,
];

export const GEOGRAPHY_GRANULARITY_FILTERS = [
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
];

export const SKILL_GRANULARITY_FILTERS = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
];

export const queryParamKeyLookup: {
  [key in SelectionListIdNames]?: string;
} = {
  [SelectionCategories.SNAPSHOT_DATE]: FilterParameterKeys.END_DATE,
  [LocalSelectionCategories.INFLOW_OR_OUTFLOW]: QueryParameterKeys.INFLOW,
};

export const presetFilterKeyLookup = {
  ...queryParamKeyLookup,
  [SelectionCategories.ROLE_K50]: FilterParameterKeys.ROLEK50,
  [SelectionCategories.ROLE_K150]: FilterParameterKeys.ROLEK150,
  [SelectionCategories.ROLE_K500]: FilterParameterKeys.ROLEK500,
  [SelectionCategories.ROLE_K1500]: FilterParameterKeys.ROLEK1500,
};

export const filterSetLabelLookup: { [key: string]: FilterSetLabelOverrides } =
  {
    // COMPANY
    [FilterSets.COMPANY_OVERVIEW]: FilterSetLabelOverrides.COMPANY_COMPOSITIONS,
    [FilterSets.COMPANY_TRANSITIONS]:
      FilterSetLabelOverrides.COMPANY_TRANSITIONS,
    [FilterSets.COMPANY_POSTINGS]: FilterSetLabelOverrides.COMPANY_POSTINGS,
    [FilterSets.COMPANY_SKILL]: FilterSetLabelOverrides.COMPANY_SKILLS,
    [FilterSets.COMPANY_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.COMPANY_SENTIMENT_RATINGS,
    [FilterSets.COMPANY_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.COMPANY_SENTIMENT_EFFECTS,
    [FilterSets.COMPANY_SENTIMENT_REVIEWS]:
      FilterSetLabelOverrides.COMPANY_SENTIMENT,

    // GEOGRAPHY
    [FilterSets.GEOGRAPHY]: FilterSetLabelOverrides.GEOGRAPHY_COMPOSITIONS,
    [FilterSets.GEOGRAPHY_TRANSITIONS]:
      FilterSetLabelOverrides.GEOGRAPHY_TRANSITIONS,
    [FilterSets.GEOGRAPHY_POSTINGS]: FilterSetLabelOverrides.GEOGRAPHY_POSTINGS,
    [FilterSets.GEOGRAPHY_SKILLS]: FilterSetLabelOverrides.GEOGRAPHY_SKILLS,
    [FilterSets.GEOGRAPHY_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.GEOGRAPHY_SENTIMENT_RATINGS,
    [FilterSets.GEOGRAPHY_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.GEOGRAPHY_SENTIMENT_EFFECTS,
    [FilterSets.GEOGRAPHY_SENTIMENT_REVIEWS]:
      FilterSetLabelOverrides.GEOGRAPHY_SENTIMENT,

    //ROLE
    [FilterSets.ROLE]: FilterSetLabelOverrides.ROLE_COMPOSITIONS,
    [FilterSets.ROLE_TRANSITIONS]: FilterSetLabelOverrides.ROLE_TRANSITIONS,
    [FilterSets.ROLE_POSTINGS]: FilterSetLabelOverrides.ROLE_POSTINGS,
    [FilterSets.ROLE_SKILLS]: FilterSetLabelOverrides.ROLE_SKILLS,
    [FilterSets.ROLE_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.ROLE_SENTIMENT_RATINGS,
    [FilterSets.ROLE_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.ROLE_SENTIMENT_EFFECTS,
    [FilterSets.ROLE_SENTIMENT_REVIEWS]: FilterSetLabelOverrides.ROLE_SENTIMENT,

    // SKILLS
    [FilterSets.SKILLS_POSTINGS]: FilterSetLabelOverrides.SKILL_POSTINGS,
  };

export const filterLabelLookup: {
  [key in
    | SelectionCategories
    | LocalSelectionCategories]?: FilterLabelOverrides;
} = {
  [SelectionCategories.SNAPSHOT_DATE]: FilterLabelOverrides.SNAPSHOT_DATE,
  [SelectionCategories.JOB_CATEGORY]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K50]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K150]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K1500]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.JA_ROLE]: FilterLabelOverrides.JA_ROLE,
  [SelectionCategories.JA_INDUSTRY]: FilterLabelOverrides.JA_INDUSTRY,
  [SelectionCategories.JA_MSA]: FilterLabelOverrides.JA_MSA,
  [SelectionCategories.JA_SENIORITY]: FilterLabelOverrides.JA_SENIORITY,
  [SelectionCategories.DATE_RANGE]: FilterLabelOverrides.DATE_RANGE,
  [SelectionCategories.DATE_RANGE_FULL]: FilterLabelOverrides.DATE_RANGE,
  [SelectionCategories.HEADCOUNT]: FilterLabelOverrides.HEADCOUNT,
  [SelectionCategories.HIRING_RATE]: FilterLabelOverrides.HIRING_RATE,
  [SelectionCategories.ATTRITION_RATE]: FilterLabelOverrides.ATTRITION_RATE,
  [SelectionCategories.GROWTH_RATE]: FilterLabelOverrides.GROWTH_RATE,
  [SelectionCategories.AVERAGE_TENURE]: FilterLabelOverrides.AVERAGE_TENURE,
  [SelectionCategories.AVERAGE_SALARY]: FilterLabelOverrides.AVERAGE_SALARY,
  [SelectionCategories.COMPANY_NAME]: FilterLabelOverrides.COMPANY_NAME,
  [SelectionCategories.COMPANY_CLEANED]: FilterLabelOverrides.COMPANY_CLEANED,
  [SelectionCategories.MSA]: FilterLabelOverrides.MSA,
  [SelectionCategories.FULL_MSA]: FilterLabelOverrides.FULL_MSA,
  [LocalSelectionCategories.PROVIDER]: FilterLabelOverrides.PROVIDER,
  [SelectionCategories.TALENTDISCOVERY_REGION]:
    FilterLabelOverrides.TALENTDISCOVERY_REGION,
  [SelectionCategories.TALENTDISCOVERY_COUNTRY]:
    FilterLabelOverrides.TALENTDISCOVERY_COUNTRY,
  [SelectionCategories.TALENTDISCOVERY_STATE]:
    FilterLabelOverrides.TALENTDISCOVERY_STATE,
  [SelectionCategories.TALENTDISCOVERY_MSA]:
    FilterLabelOverrides.TALENTDISCOVERY_MSA,
  [SelectionCategories.TALENTDISCOVERY_SKILL]:
    FilterLabelOverrides.TALENTDISCOVERY_SKILL,
  [SelectionCategories.TALENTDISCOVERY_ETHNICITY]:
    FilterLabelOverrides.TALENTDISCOVERY_ETHNICITY,
  [SelectionCategories.DIVERSITY_INCLUSION]:
    FilterLabelOverrides.DIVERSITY_INCLUSION,
  [SelectionCategories.STATE]: FilterLabelOverrides.STATE,
  [SelectionCategories.COMPANY_FULL]: FilterLabelOverrides.COMPANY_FULL,
  [SelectionCategories.INDUSTRY_FULL]: FilterLabelOverrides.INDUSTRY_FULL,
  [SelectionCategories.TAXONOMY_JOB]: FilterLabelOverrides.TAXONOMY_JOB,
  [SelectionCategories.TAXONOMY_SKILL]: FilterLabelOverrides.TAXONOMY_SKILL,
  [SelectionCategories.TAXONOMY_ACTIVITY]:
    FilterLabelOverrides.TAXONOMY_ACTIVITY,
  [SelectionCategories.HIGHEST_DEGREE]: FilterLabelOverrides.HIGHEST_DEGREE,
  [SelectionCategories.COMPANY_NEW_DASHBOARD]:
    FilterLabelOverrides.COMPANY_NEW_DASHBOARD,
  [SelectionCategories.INDUSTRY_NEW_DASHBOARD]:
    FilterLabelOverrides.INDUSTRY_NEW_DASHBOARD,
  [LocalSelectionCategories.DATA_METRIC]: FilterLabelOverrides.DATA_METRIC,
  [LocalSelectionCategories.METRIC_MODE]: FilterLabelOverrides.METRIC_MODE,
  [SelectionCategories.RSID]: FilterLabelOverrides.RSID,
  [LocalSelectionCategories.FLIGHT_RISK]: FilterLabelOverrides.FLIGHT_RISK,
  [LocalSelectionCategories.REMOTE_SUITABILITY]:
    FilterLabelOverrides.REMOTE_SUITABILITY,
  [SelectionCategories.BASE_SALARY]: FilterLabelOverrides.BASE_SALARY,
  [SelectionCategories.TOTAL_COMPENSATION]:
    FilterLabelOverrides.TOTAL_COMPENSATION,
  [SelectionCategories.SKILL_K75]: FilterLabelOverrides.SKILL,
  [SelectionCategories.SKILL_K700]: FilterLabelOverrides.SKILL,
  [SelectionCategories.SKILL_K3000]: FilterLabelOverrides.SKILL,
  [SelectionCategories.RAW_TITLE]: FilterLabelOverrides.RAW_TITLE,
};

export const TempFilterOverrideLookup = {
  [SelectionCategories.COMPANY_NEW_DASHBOARD]: SelectionCategories.COMPANY,
  [SelectionCategories.INDUSTRY_NEW_DASHBOARD]: SelectionCategories.INDUSTRY,
};

export const SHARED_SET_ENTITY_LIMIT = 6;

export const operatorOptions: OperatorOptionsType = [
  {
    label: 'is between',
    value: OPValues.BETWEEN,
    filterName: OPValues.BETWEEN,
  },
  {
    label: 'is greater than',
    value: OPValues.GREATER,
    filterName: OPValues.GREATER,
  },
  { label: 'is less than', value: OPValues.LESS, filterName: OPValues.LESS },
];

export const notMultiFilters: string[] = [
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  SelectionCategories.SNAPSHOT_DATE,
  LocalSelectionCategories.DATA_METRIC,
  LocalSelectionCategories.METRIC_MODE,
];

export const FILTER_STORAGE_KEY = 'filterStore';

export const FilterStateDefaultsMap: {
  [key in FilterOrSubfilterName]?: Partial<
    SelectFilter<FilterList<ValidValueTypes>>
  >;
} = {
  [SubFilterNames.SUB_ROLE]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Engineer' }],
    selectionListId: SelectionCategories.JOB_CATEGORY,
  },
  [SubFilterNames.SUB_REGION]: {
    isMulti: true,
    value: [{ id: '3', shortName: 'W Europe' }],
    selectionListId: SelectionCategories.REGION,
  },
  [SubFilterNames.SUB_INDUSTRY]: {
    isMulti: true,
    value: [{ id: '10', shortName: 'Comm Serv' }],
    selectionListId: SelectionCategories.INDUSTRY,
  },
  [SubFilterNames.SUB_SKILL]: {
    isMulti: true,
    value: [{ id: '4', shortName: 'Operations' }],
    selectionListId: SelectionCategories.SKILL,
  },

  // NOTE: Chose whichever item corresponds to id 4 to mirror the old
  // skills list above. Can change the default to whatever we want
  [SubFilterNames.SUB_SKILL_OVERTIME]: {
    isMulti: true,
    value: [
      { id: '4', shortName: 'Analysis / Data Analysis / Analytical Skills' },
    ],
    selectionListId: SelectionCategories.SKILL_K75,
  },
  [SubFilterNames.SUB_SENIORITY]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Junior' }],
    selectionListId: SelectionCategories.SENIORITY,
  },
  [SubFilterNames.SUB_GENDER]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Female' }],
    selectionListId: SelectionCategories.GENDER,
  },
  [SubFilterNames.SUB_ETHNICITY]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Asian' }],
    selectionListId: SelectionCategories.ETHNICITY,
  },
  [SubFilterNames.SUB_EDUCATION]: {
    isMulti: true,
    value: [{ id: '3', label: 'Bachelor', shortName: 'Bach.' }],
    selectionListId: SelectionCategories.HIGHEST_DEGREE,
  },
};

export const subSkillDefault = {
  isMulti: true,
  value: [{ id: 4, shortName: 'Operations' }],
  selectionListId: SelectionCategories.SKILL,
};

export const subKeywordDefault = {
  isMulti: true,
  value: [{ id: 10, shortName: 'Python', keyword_category: 27, parentId: 27 }],
  selectionListId: SelectionCategories.KEYWORD,
};

export const SkillsFilterStateDefaultMap: {
  [key in FilterOrSubfilterName]?: Partial<
    SelectFilter<FilterList<ValidValueTypes>>
  >;
} = {
  [SubFilterNames.SUB_SKILL]: subSkillDefault,
  [SubFilterNames.SUB_KEYWORD]: subKeywordDefault,
};
