/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  select,
  selectAll,
  scaleBand,
  scaleLinear,
  min,
  max,
  scaleOrdinal,
} from 'd3';
import { format as d3Format } from 'd3-format';
import { roundedRect } from '../../utilities/corner-radius';
import { Views, getColorKeyByIdAndName } from '@revelio/core';
import { appendWatermark } from '../../utilities/append-watermark';
import { generateInlineStyles } from '../../utilities/generate-inline-styles';
import '../../d3-styles.scss';
import { drawSentimentEffectsArrows } from '../../utilities/draw-sentiment-effects-arrows';
import { colors } from '../../utilities/colors';
import { get, has, isUndefined } from 'lodash';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { appendTitle } from '../../utilities/append-title';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { generateSVGLegend } from '../stacked-bar-chart-horizontal/helpers';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { GroupedBarData } from './types';
import { drawBarValueLabels } from './helpers';

interface GroupedBarPlotConfig extends PlotConfig<GroupedBarData[]> {
  ttMainFormat: any;
  ttSecondaryFormat: any;
  useShortName: any;
  view: any;
  colorLookup: any;
  useColorLookup: any;
  isFullHeight: any;
  isCentered: any;
  labelSize: any;
  showSentimentEffectArrows: any;
  isSharesPlot: any;
  chartPadding: any;
  innerPadding: any;
  isXScaleNice: any;
  setChartHasRendered?: (hasRendered: boolean) => void;
  showBarValueLabels?: boolean;
}

export const GroupedBarChartHorizontalGenerator = (
  plotConfigs: GroupedBarPlotConfig,
  downloadOptions: DownloadOptions
): SVGSVGElement | null | void => {
  let { name, height, width } = plotConfigs;
  const {
    data,
    ttMainFormat,
    ttSecondaryFormat,
    targetRef,
    requestHash,
    useShortName,
    view,
    colorLookup,
    isRenderingOrLoading,
    useColorLookup = true,
    isFullHeight = false,
    isCentered = true,
    labelSize = 16,
    showSentimentEffectArrows = view === Views.SENTIMENT_EFFECT,
    isSharesPlot = false,
    chartPadding = { top: 0, right: 0, bottom: 0, left: 0 },
    innerPadding = 0.45,
    isXScaleNice = true,
    customMargins,
    setChartHasRendered,
    showBarValueLabels = false,
  } = plotConfigs;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;
  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();
    // setup margins and inner dims
    dims.margin = {
      top: 30 + chartPadding.top,
      left: (isCentered ? 140 : 80) + chartPadding.left,
      bottom: (showSentimentEffectArrows ? 26 : 5) + chartPadding.bottom,
      right: (isCentered ? 35 : 0) + chartPadding.right,
    };

    if (showBarValueLabels) {
      dims.margin.right += 30;
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    // setup svg node
    const node = targetRef?.current || containerId;
    const svg = select(node).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');

    if (download) {
      const legendSet: Set<any> = new Set();
      const legendData: any[] = [];

      let appendOther = false;
      data.forEach((d: any) => {
        if (d.metadata.shortName.toLowerCase() === 'other') {
          appendOther = true;
        }
        legendSet.add(d.metadata.shortName);
        legendData.push({ key: d.metadata.shortName });
      });

      if (appendOther) {
        legendSet.add('Other');
      }

      const otherColor = '#A0A9B8';
      const color = scaleOrdinal().domain(legendSet).range(colors);

      const legendContainer = generateSVGLegend({
        chart: svg,
        width,
        height,
        dims,
        stackedData: legendData,
        color,
        otherColor,
        fontColor: '#636d7e',
      });

      legendContainer.style('transform', `translate(20px, ${height - 40}px)`);

      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        legendContainerHeight: legendContainer.node().getBBox().height,
        padding,
      });
    }

    //START D3 CODE=============================================================================

    const minValue: any = min(
      data.map((d: any) => d.value.map((r: any) => r.value)).flat()
    );
    const maxValue: any = max(
      data.map((d: any) => d.value.map((r: any) => r.value)).flat()
    );

    const shortNameMap: any = {};

    const groups = new Set(
      data
        .map((d) =>
          d.value.map((r) => {
            const longName = r.metadata?.longName || (r as any).longName;

            if (!has(shortNameMap, longName) && r.metadata) {
              shortNameMap[longName] = r.metadata.shortName;
            }

            return longName;
          })
        )
        .flat()
    );

    const subgroups = data.map((d) => {
      return useShortName
        ? { id: d.id, name: d.metadata.shortName }
        : { id: d.id, name: d.metadata.longName };
    });

    const colorDomain = useColorLookup ? Object.keys(colorLookup) : colors;

    const colorRange = useColorLookup
      ? colorDomain.map((key) => colorLookup[key]?.color)
      : colors;

    const colorScale = scaleOrdinal().domain(colorDomain).range(colorRange);

    //transform data to nest properly for group & subgroup
    const dataTransformed: any[] = [];

    groups.forEach((group) => {
      // transform data
      const newData: any = {};
      newData.group_name = group;

      data.forEach((primarySelection) => {
        const dVal = primarySelection.value.find((s) => {
          return (s.metadata?.longName || (s as any).longName) === group;
        });

        if (dVal) {
          const lookupKey: any = useShortName
            ? primarySelection.metadata.shortName
            : primarySelection.metadata.longName;

          if (dVal.metadata?.count || (dVal as any).count) {
            if (!newData['count']) {
              newData['count'] = {};
            }
            newData['count'][lookupKey] =
              dVal.metadata?.count || (dVal as any).count;
          }

          newData[lookupKey] = dVal.value;
        } else {
          newData[
            useShortName
              ? (primarySelection.metadata.shortName as any)
              : primarySelection.metadata.longName
          ] = 0;
        }
      });

      dataTransformed.push(newData);
    });
    // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    const innerPad = innerPadding; //this is a percentage of step
    const maxStep = 60; //space from start of 1 bar to start of the next
    let yAxisRange = [0, dims.innerHeight];
    // readjust the y axis range to make the chart height (sum of all steps - height of bars + innerpaddings) dependent on # bars and vertically centered
    const chartHeight = groups.size * maxStep;
    if (chartHeight < dims.innerHeight && !isFullHeight) {
      yAxisRange = [
        dims.innerHeight / 2 - chartHeight / 2,
        dims.innerHeight / 2 + chartHeight / 2,
      ];
    }

    const yScale = scaleBand()
      .domain(groups)
      .range(yAxisRange)
      .paddingInner(innerPad);

    const ySubgroupScale = scaleBand()
      .domain(
        data.map((d: any) =>
          useShortName ? d.metadata.shortName : d.metadata.longName
        )
      )
      .range([0, yScale.bandwidth()]);

    // Y Axis Labels
    const labels = chart
      .append('g')
      .selectAll('.textBox')
      .data(groups)
      .enter()
      .append('foreignObject');

    labels
      .attr('width', dims.margin.left)
      .attr('height', yScale.bandwidth())
      .attr('data-testid', 'grouped-bar-chart-category-axis-label')
      .style('overflow', 'visible')
      .attr('transform', (d) => {
        return `translate(${-dims.margin.left}, ${yScale(d)})`;
      })
      .append('xhtml:div')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'flex-end')
      .style('height', '100%')
      .style('width', '100%')
      // .style('padding-right', '10px')
      .append('text')
      .classed(`inline-style-target-${name}`, !!download)
      .classed('grouped-bar-chart-horizontal-axis-label', !isCentered)
      .classed('mirror-bar-chart-horizontal-axis-label', isCentered)
      .style('overflow', 'hidden')
      .text((d) => {
        return shortNameMap[d] || d;
      })
      .style('display', '-webkit-box')
      .style('-webkit-box-orient', 'vertical')
      .style('-webkit-line-clamp', '2')
      .style('line-clamp', '2')
      .style('text-align', 'right')
      .style('text-overflow', 'ellipsis')
      .style('margin-right', '10px')
      .style('font-size', `${labelSize}px`)
      .style('float', 'right')
      .style('width', '100%');

    let minHeight: any; // height of a single line

    labels
      .selectAll('.grouped-bar-chart-horizontal-axis-label')
      .each(function () {
        // determine height of unwrapped line
        const rectHeight = (this as any)?.getBoundingClientRect()?.height;

        if (isUndefined(minHeight) || minHeight > rectHeight) {
          minHeight = rectHeight;
        }
      })
      .style('line-height', function () {
        const rectHeight = (this as any)?.getBoundingClientRect()?.height;

        if (rectHeight > minHeight) {
          return '0.7rem';
        }

        return 'normal';
      });

    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    const xDomain = [Math.min(minValue, 0), maxValue];
    const xRange = [0, dims.innerWidth];

    const xScale = isXScaleNice
      ? scaleLinear().domain(xDomain).range(xRange).nice()
      : scaleLinear().domain(xDomain).range(xRange);

    const tooltipHeight = view === Views.SENTIMENT_EFFECT ? 75 : 58;

    const tooltip = select(`.react-node-${name}`)
      .append('div')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .attr('class', 'tooltip-mirror-bar-chart-horizontal')
      .classed(`tooltip-${name}`, true)
      .attr('data-testid', 'grouped-bar-chart-tooltip');

    const mouseOver = (event: any, d: any) => {
      //show tooltip
      tooltip
        .style('opacity', 1)
        .style('display', null)
        .style('transform', function () {
          if (view === Views.SENTIMENT_EFFECT) {
            tooltip.html(
              d.value > 0
                ? `<span style="font-weight:bold; font-size: 12px">${d.key} - ${
                    d.group
                  }</span><br>Topic Score: ${d3Format(ttMainFormat)(
                    d.value
                  )}<br>(Sample Size: ${d3Format(ttSecondaryFormat)(d.count)})`
                : `<span style="font-weight:bold; font-size: 12px">${d.key} - ${
                    d.group
                  }</span><br>Topic Score: ${d3Format(ttMainFormat)(
                    d.value
                  )}<br>(Sample Size: ${d3Format(ttSecondaryFormat)(d.count)})`
            );
          } else {
            tooltip.html(
              isSharesPlot
                ? `<span style="font-weight:bold; font-size: 12px">${
                    d.key
                  }</span><br>${d.group}: ${d3Format(ttMainFormat)(
                    d.count
                  )} / ${d3Format(ttSecondaryFormat)(d.value / 100)}`
                : `<span style="font-weight:bold; font-size: 12px">${
                    d.key
                  }</span><br>${d.group}: ${d3Format(ttMainFormat)(d.value)}`
            );
          }

          const tooltipRect: any = this?.getBoundingClientRect();
          const tooltipWidth = tooltipRect.width;
          if (!isCentered) {
            const xPosition =
              dims.margin.left - tooltipWidth / 2 + xScale(d.value) / 2;

            const yPosition =
              dims.margin.top +
              yScale(d.group) +
              ySubgroupScale(d.key) -
              tooltipRect?.height -
              7;

            return `translate(${xPosition}px, ${yPosition}px)`;
          } else {
            return `translate(${
              // eslint-disable-next-line no-nested-ternary
              xScale.domain()[0] < 0 && xScale.domain()[1] > 0
                ? dims.margin.left +
                  min([xScale(d.value), xScale(0)]) +
                  Math.abs(xScale(d.value) - xScale(0)) / 2 -
                  tooltipWidth / 2
                : xScale.domain()[0] > 0
                  ? dims.margin.left +
                    min([xScale(d.value)]) +
                    Math.abs(xScale(d.value)) / 2 -
                    tooltipWidth / 2
                  : dims.margin.left +
                    xScale(d.value) +
                    //max([xScale(d.value), xScale(xScale.domain()[0])]) +
                    Math.abs(xScale(d.value) - xScale(xScale.domain()[1])) / 2 -
                    tooltipWidth / 2
            }px, ${
              dims.margin.top +
              yScale(d.group) +
              ySubgroupScale(d.key) -
              tooltipHeight -
              7
            }px)`;
          }
        });

      selectAll(`.${name}-bar`)
        .filter((row: any) => row.key != d.key)
        .style('opacity', 0.5);
    };

    const mouseOut = (event: any, d: any) => {
      tooltip.style('opacity', 0).style('display', 'none');
      selectAll(`.${name}-bar`).style('opacity', (d: any) =>
        d.value >= 0 ? 1 : 0.7
      );
    };

    chart
      .append('g')
      .selectAll('g')
      .data(dataTransformed)
      .join('g')
      .attr('data-testid', 'grouped-bar-chart-group')
      .attr('transform', (d) => `translate(${0}, ${yScale(d.group_name)})`)
      .selectAll('.bar')
      .data(function (d) {
        const mapped = subgroups.map(function (key: any) {
          return {
            id: key.id,
            key: key.name,
            value: d[key.name],
            group: d.group_name,
            count: get(d.count, key.name),
          };
        });

        return mapped;
      })
      .join('path')
      .attr('class', 'bar')
      .attr('data-testid', `grouped-bar-chart-bar`)
      .classed(`${name}-bar`, true)
      .attr('d', (d) => {
        const smallestDomainValue = xScale.domain()[0];
        let xPosition;

        if (smallestDomainValue === 0 && !isCentered) {
          xPosition = 0;
        } else if (smallestDomainValue < 0) {
          xPosition = xScale(Math.min(d.value, 0));
        } else {
          xPosition = xScale(xScale.domain()[0]);
        }

        const yPosition = ySubgroupScale(d.key);

        const width =
          xScale.domain()[0] < 0
            ? Math.abs(xScale(d.value) - xScale(0))
            : xScale(d.value);

        return roundedRect(
          xPosition,
          yPosition,
          width,
          ySubgroupScale.bandwidth(),
          [2.5, 2.5, 2.5, 2.5]
        );
      })
      .attr('fill', (d: any, i: number) => {
        const idString = d.id?.toString();
        // TODO: Lift this logic higher to be more centralized
        // using magic string here because importing the enum/variables causes a circular dependency
        if (
          idString.includes('company') &&
          !idString.includes('python_company')
        ) {
          const colorKey = d.id.toString().replace('company', 'python_company');

          return colorScale(colorKey) as any;
        }

        return (
          colorScale(getColorKeyByIdAndName(d.id, d.key, d.key) || '') ||
          colors[i]
        );
      })
      .attr('opacity', (d) => (d.value >= 0 ? 1 : 0.7))
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);

    if (showBarValueLabels) {
      drawBarValueLabels(
        chart,
        data[0].value,
        { format: '.0%', fontSize: 11.5 },
        { xScale, yScale, metaValue: 'shortName' }
      )
        .attr('cursor', 'pointer')
        .on('mouseover', mouseOver)
        .on('mouseout', mouseOut);
    }

    if (showSentimentEffectArrows) {
      drawSentimentEffectsArrows(
        chart,
        dims,
        {
          translateX1: download ? 0 : (xScale(0) - xScale(minValue)) / 2,
          translateY1: dims.innerHeight + 22,
        },
        {
          translateX2: download
            ? dims.innerWidth - dims.margin.right
            : xScale(0) + (xScale(maxValue) - xScale(0)) / 2,
          translateY2: dims.innerHeight + 22,
        }
      );
    }

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () => {
        isRenderingOrLoading?.next(false);
        setChartHasRendered?.(true);
      });
    }

    if (download) {
      generateInlineStyles([
        `.inline-style-target-${name}`,
        '.mirror-bar-chart-horizontal-arrow',
        '.mirror-bar-chart-horizontal-text',
      ]);
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
