/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { AsyncSelect } from 'chakra-react-select';
import {
  SelectionCategories,
  filterStore,
  useSelectionLists,
  useViewFilterDefaults,
} from '@revelio/filtering';
import { useNavigate } from 'react-router';
import { capitalize, groupBy, isEmpty, mapValues } from 'lodash';
import {
  chakraStyles,
  defaultSelectionMap,
  entityMap,
  filterAndMergeValuesOptimized,
  filterEntities,
  generateDeepLink,
  getUrlParams,
  landingSelectionLists,
  placeholderMap,
  simplePluralize,
} from './landingUtils';
import { TourClasses, Views } from '@revelio/core';

const LandingSearch = () => {
  const selectInputRef: any = useRef();
  const [entity, setEntity] = useState(SelectionCategories.COMPANY);
  const [selectValue, setSelectValue] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();
  const data = useSelectionLists(landingSelectionLists);

  useViewFilterDefaults({
    view: Views.OVERVIEW,
    viewFilters: landingSelectionLists,
  });

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void,
    entityValue: string
  ) => {
    const entityFilter = entityMap[entityValue];
    const entityData = filterAndMergeValuesOptimized(data, entityFilter);
    callback(filterEntities(inputValue, entityData));
  };

  const handleContinue = () => {
    const groupData = groupBy(selectValue, 'entityName');
    const finalData = mapValues(groupData, (value: any, key: any) => ({
      value: value.map((item: any) => item.id),
      selectionListId: key,
    }));

    const urlParams = getUrlParams(finalData);

    let link = '';
    const totalEntityValueCount = Object.keys(finalData).reduce((sum, key) => {
      return sum + finalData[key].value.length;
    }, 0);
    if (totalEntityValueCount > 1) {
      link = generateDeepLink(entity, urlParams);
    } else {
      const defaults = filterStore.query(
        (store) => store.viewFilterDefaultEntities
      );

      const defaultValues = (defaults.overview as any).value[
        defaultSelectionMap[entity]
      ].map((v: any) => v.id);
      const userValueArray: any = Object.keys(finalData);
      const hasDefault = userValueArray[0] in defaults.overview.value;
      const finalData2 = mapValues(finalData, (value: any, key: any) => ({
        value: hasDefault
          ? [...value.value, ...defaultValues.slice(1)]
          : value.value,
        selectionListId: key,
      }));
      if (!hasDefault) {
        finalData2[`${defaultSelectionMap[entity]}`] = {
          value: defaultValues.slice(1),
          selectionListId: defaultSelectionMap[entity],
        };
      }
      const urlParams2 = getUrlParams(finalData2);
      link = generateDeepLink(entity, urlParams2);
    }
    navigate(link);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (selectValue && !menuIsOpen) {
        handleContinue();
        setSelectValue([]);
      }
    }
  };

  // Workaround to fix async menu placement bug
  const rootHeight = document?.getElementById('next')?.offsetHeight || 0;
  const selectElement: HTMLElement | null =
    document?.getElementById('async-select-comp');
  const selectOffsetBottom =
    (selectElement && selectElement?.offsetHeight + selectElement?.offsetTop) ||
    0;

  const entityLimitCount = 6 - selectValue.length;

  return (
    <VStack
      w="100%"
      alignItems="center"
      gap={4}
      className={TourClasses.TOUR_TRACKING_CLASS}
    >
      <Text fontWeight="semibold" textAlign="center">
        Or search for an entity
      </Text>
      <Flex
        w="100%"
        gap={3}
        justifyContent="center"
        alignItems="center"
        flexDirection={{ sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
      >
        <Flex w={{ sm: '100%', md: '100%', lg: '50%', xl: '40%' }} h="100%">
          <Flex h="100%">
            <Menu closeOnSelect={true}>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                colorScheme="gray"
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                h="auto"
                color="text.primary"
                px={5}
              >
                {capitalize(entity)}
              </MenuButton>
              <MenuList minWidth="240px" color="text.primary">
                <MenuOptionGroup
                  defaultValue={SelectionCategories.COMPANY}
                  title="Entities"
                  type="radio"
                  onChange={(e: any) => {
                    setEntity(e);
                    selectInputRef?.current?.clearValue();
                  }}
                >
                  <MenuItemOption value={SelectionCategories.COMPANY}>
                    Company
                  </MenuItemOption>
                  <MenuItemOption value={SelectionCategories.GEOGRAPHY}>
                    Geography
                  </MenuItemOption>
                  <MenuItemOption value={SelectionCategories.ROLES}>
                    Role
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Flex>
          <Flex w="100%" h="100%" position="relative">
            <AsyncSelect
              ref={selectInputRef}
              id="async-select-comp"
              colorScheme="green"
              isMulti
              placeholder={placeholderMap[entity]}
              size="md"
              chakraStyles={chakraStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              loadOptions={(inputValue, callback) =>
                loadOptions(inputValue, callback, entity)
              }
              getOptionLabel={(option: any) => option.shortName}
              getOptionValue={(option: any) => option.id}
              noOptionsMessage={({ inputValue }: any) =>
                /* eslint-disable-next-line no-nested-ternary */
                inputValue
                  ? 'No items found'
                  : selectValue.length >= 6
                    ? `You can only choose up to 6 ${simplePluralize(entity)}!`
                    : 'Start typing to see results...'
              }
              onChange={(val: any) => setSelectValue(val)}
              menuPlacement={
                rootHeight - (selectOffsetBottom + 200) > 770 ? 'bottom' : 'top'
              }
              isOptionDisabled={(option) => selectValue.length >= 6}
              menuIsOpen={menuIsOpen}
              onMenuOpen={() => setMenuIsOpen(true)}
              onMenuClose={() => setMenuIsOpen(false)}
              onKeyDown={handleKeyDown}
            />
            {selectValue.length > 0 && selectValue.length < 6 && (
              <HStack position="absolute" bottom={-6} gap={1} opacity={0.7}>
                <Icon as={InfoOutlineIcon} boxSize={3} color="text.primary" />
                <Text fontSize="sm">{`Add up to ${entityLimitCount} more ${
                  entityLimitCount === 1 ? entity : simplePluralize(entity)
                }`}</Text>
              </HStack>
            )}
          </Flex>
        </Flex>
        <Box>
          <Button
            colorScheme="green"
            px={6}
            size="md"
            onClick={handleContinue}
            isDisabled={isEmpty(selectValue)}
          >
            Continue
          </Button>
        </Box>
      </Flex>
    </VStack>
  );
};

export default LandingSearch;
