import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  RICS_INDUSTRY_FILTERS,
} from '@revelio/filtering';
import Postings, { providerFilterId } from '../../../postings/postings';
import { useFlag } from '@unleash/proxy-client-react';

const title = [PageTitles.GEOGRAPHY, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: [
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
      SelectionCategories.METRO_AREA,
    ],
    isNested: true,
  },
];

const otherFilters = [providerFilterId];

export function GeographyPostings() {
  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.GeoPostingsKeyword);
  const titleFiltersFeatureFlag = useFlag(FeatureFlag.GeoPostingsTitle);

  const selectableFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: RICS_INDUSTRY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE_FULL,
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
    ...(titleFiltersFeatureFlag ? [SelectionCategories.RAW_TITLE] : []),
    LocalSelectionCategories.METRIC_MODE,
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.GEOGRAPHY}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.METRO_AREA}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_POSTINGS}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={PrimaryFilterLimits.POSTINGS}
      otherFilters={otherFilters}
      disableParentSelect={false}
      isGqlQuery={true}
      isGoRequest={true}
      additionalNonActiveFilters={[providerFilterId]}
    />
  );
}

export default GeographyPostings;
