import { deburr } from 'lodash';
import { TNode } from '../Node';

export const handleSearch = (node: TNode, search: string) =>
  [
    node.data.name,
    ...(node.data.secondaryLabel ? [node.data.secondaryLabel] : []),
  ].some((identifier) =>
    deburr(identifier.toLowerCase().trim()).includes(
      search.toLowerCase().trim()
    )
  );
