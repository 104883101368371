import { Client } from 'urql';
import { CustomRoleTaxonomySelection, graphql } from '@revelio/data-access';
import {
  RoleSelectionCategories,
  SelectionCategories,
  SelectionListIdNames,
} from './filters.model';
import { get } from 'lodash';
import {
  addSelectionList,
  selectionListDataSource,
} from './filters.repository';
import { trackRequestResult } from '@ngneat/elf-requests';
import { of, switchMap } from 'rxjs';
import {
  INDUSTRY_AND_COMPANY_FILTERS,
  REPORT_COMPANY_FILTERS,
} from './filters.constants';
import { transformCustomRoleTaxonomy } from './selection-lists-data-transforms';

const SelectionListQuery = graphql(`
  query SelectionList {
    selectionList {
      region {
        id
        shortName
        longName
        label: longName
      }
      country {
        id
        shortName
        longName
        label: longName
        region
      }
      state {
        id
        shortName
        longName
        label: longName
        region
        country
      }
      metro_area: msa {
        id
        shortName
        longName
        label: longName
        region
        country
        state
      }
      job_category {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
      }
      role_k50 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
      }
      role_k150 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
      }
      role_k300 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
      }
      role_k500 {
        id
        shortName
        longName: shortName
        label: shortName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
      }
      role_k1000 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
      }
      role_k1250 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
      }
      role_k1500 {
        id
        shortName
        longName
        label: longName
        topCleanedTitles
        topRawTitles
        job_category: roleK7
        role_k50: roleK50
        role_k150: roleK150
        role_k300: roleK300
        role_k500: roleK500
        role_k1000: roleK1000
        role_k1250: roleK1250
      }
      seniority {
        id
        shortName
        longName
        label: longName
      }
      ethnicity {
        id
        shortName
        longName
        label: longName
      }
      gender {
        id
        shortName
        longName
        label: longName
      }
      highest_degree {
        id
        shortName
        longName
        label: longName
      }
      education: highest_degree {
        id
        shortName
        longName
        label: longName
      }
      skill_k25 {
        id
        shortName
        longName: shortName
        label: shortName
      }
      skill_k50 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
      }
      skill_k75 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        topSkills
      }
      skill_k700 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        topSkills
      }
      skill_k2500 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
      }
      skill_mapped {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
        skill_k2500
      }
      skill_k3000 {
        id
        shortName
        longName: shortName
        label: shortName
        skill_k25
        skill_k50
        skill_k75
        skill_k700
        skill_k2500
        topSkills
      }
    }
  }
`);

const CompanyReportSelectionListQuery = graphql(`
  query CompanyReportSelectionList {
    selectionList {
      company_report {
        id
        shortName
        longName
        label: shortName
        industry: industryId
        rank
        isin
        sedol
        ticker
        rcid
        version
        dataUnavailableIn {
          position
          posting
          sentiment
          sentimentReview
          transition
        }
      }
    }
  }
`);

const CompanyIndustrySelectionListQuery = graphql(`
  query CompanyIndustySelectionList {
    selectionList {
      company {
        id
        shortName
        rank
        ticker
        longName
        label: longName
        industry: industryId
        ricsK50Id
        rics_k50: ricsK50Id
        sedol
        isin
        rcid
      }
      industry {
        id
        shortName
        longName
        label: longName
      }
      rics_k10 {
        id
        shortName
        label: longName
        longName
      }
      rics_k50 {
        id
        shortName
        longName
        label: longName
        rics_k10
      }
      rics_k400 {
        id
        shortName
        longName
        rics_k10
        rics_k50
      }
    }
  }
`);

const CustomRoleTaxonomyQuery = graphql(`
  query CustomRoleTaxonomy($taxonomyId: CustomRoleTaxonomySelection!) {
    selectionList {
      custom_role_taxonomy(taxonomy_id: $taxonomyId) {
        orderedLevels {
          id
          label
        }
        leafLevelData {
          id
          label
          levelId
          parents {
            id
            levelId
            label
          }
        }
      }
    }
  }
`);

export type SelectionListParentMap = {
  [key in SelectionListIdNames]?: SelectionListIdNames;
};
export function fetchGqlSelectionList(
  gqlClient: Client,
  selectionListNeeded: SelectionListIdNames
) {
  const query = (() => {
    if (
      INDUSTRY_AND_COMPANY_FILTERS.includes(
        selectionListNeeded as SelectionCategories
      )
    ) {
      return CompanyIndustrySelectionListQuery;
    }

    if (
      REPORT_COMPANY_FILTERS.includes(
        selectionListNeeded as SelectionCategories
      )
    ) {
      return CompanyReportSelectionListQuery;
    }

    return SelectionListQuery;
  })();

  return of(selectionListNeeded).pipe(
    trackRequestResult([selectionListNeeded], {
      preventConcurrentRequest: true,
      cacheResponseData: true,
    }),
    selectionListDataSource.skipWhileCached({ key: selectionListNeeded }),
    switchMap((listName: SelectionListIdNames) => {
      return gqlClient
        .query(query, {})
        .toPromise()
        .then((resp) => {
          const requestedList = get(resp, `data.selectionList.${listName}`, []);
          const list = {
            id: listName,
            label: listName as string,
            value: requestedList,
          };
          addSelectionList(list.id, list.value);
          return list;
        })
        .catch((error) => {
          console.log('GQL list error:', error);
        });
    })
  );
}

export const fetchGqlCustomRoleTaxonomy = ({
  gqlClient,
  selectionListNeeded,
  roleTaxonomyId,
}: {
  gqlClient: Client;
  selectionListNeeded: RoleSelectionCategories;
  roleTaxonomyId: CustomRoleTaxonomySelection;
}) => {
  return of(selectionListNeeded).pipe(
    trackRequestResult([selectionListNeeded], {
      preventConcurrentRequest: true,
      cacheResponseData: true,
    }),
    selectionListDataSource.skipWhileCached({
      key: selectionListNeeded,
    }),
    switchMap((listName: RoleSelectionCategories) => {
      return gqlClient
        .query(CustomRoleTaxonomyQuery, {
          taxonomyId: roleTaxonomyId,
        })
        .toPromise()
        .then((resp) => {
          const list = transformCustomRoleTaxonomy(
            listName,
            get(resp, 'data.selectionList.custom_role_taxonomy', [])
          );
          addSelectionList(list.id, list.value, roleTaxonomyId);
          return list;
        })
        .catch((error) => {
          console.log('GQL list error:', error);
        });
    })
  );
};
