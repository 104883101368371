import {
  FilterMenuItem,
  FilterMenuItemOrConfig,
  ISelectFilter,
  OPSelectFilter,
  OPValues,
  SelectableCategories,
  SelectionCategories,
} from '../../engine/filters.model';
import { get, isString, replace, startCase } from 'lodash';
import {
  filterLabelLookup,
  notMultiFilters,
} from '../../engine/filters.constants';
import { startCasePreserveChars } from '@revelio/core';

/**
 * Takes a config array to specify the filters to show in the FilterMenu component.
 * Parses this array and creates a grouped array of SelectionCategories that can be passed into
 * the filters prop of the FilterMenu component
 *
 * @param selectableFilters filter configs to parse
 *
 * @returns a grouped array of SelectionCategories
 */
export const createSelectableFiltersMap = (
  selectableFilters: FilterMenuItemOrConfig[]
): FilterMenuItem[] => {
  return selectableFilters.map((sf) => {
    const isArrayOrString = Array.isArray(sf) || isString(sf);

    if (isArrayOrString) {
      return sf;
    }

    return get(sf, 'filters', []);
  });
};

export const mapSelectableFilters = (
  filters: FilterMenuItemOrConfig[],
  filtersToDisable: SelectableCategories[],
  defaultLimit?: number
) => {
  const flatFilterNames: SelectableCategories[] = [];
  const mappedFilters = filters.map((f) => {
    const isArray = Array.isArray;

    const isArrayOrString = isArray(f) || isString(f);

    const selections = get(f, 'selections');

    const showDivider = get(f, 'showDivider');

    if (selections) {
      const heading: SelectableCategories = get(f, 'heading', '');
      const label = filterLabelLookup[heading] || heading;

      return {
        filterName: heading,
        isParentListDisabled: false,
        value: selections,
        showDivider,
        isMulti: false,
        label: startCasePreserveChars(label, ['&', 'a']),
        isNested: false,
        limit: defaultLimit,
      };
    }

    const filterItem = isArrayOrString ? f : get(f, 'filters', []);

    const isNested = isArrayOrString ? false : f.isNested;

    const limit = isArrayOrString
      ? defaultLimit
      : get(f, 'limit', defaultLimit);
    // TODO: temp for Company Skills
    let filterName = isArray(filterItem) ? filterItem[0] : filterItem;
    if (filterName == ('python_company' as SelectableCategories)) {
      filterName = SelectionCategories.COMPANY;
    }
    flatFilterNames.push(filterName);

    const isParentListDisabled =
      isArray(filterItem) &&
      filtersToDisable.length > 0 &&
      filtersToDisable.some((fil) => filterItem.includes(fil));

    const labelLookup = isArray(filterItem)
      ? GraphFilterLabelLookup
      : filterLabelLookup;

    const label = labelLookup[filterName];

    const isMulti = !notMultiFilters.includes(filterName);

    return {
      filterName,
      isParentListDisabled,
      value: filterItem,
      isMulti: isMulti,
      label: replace(startCase(label || filterName), 'Amp', '&'),
      isNested,
      limit,
    };
  });

  return { flatFilterNames, mappedFilters };
};

enum GraphFilterLabel {
  COMPANY = 'company &amp; industry',
  INDUSTRY = 'industry',
  ROLE = 'role',
  GEOGRAPHY = 'geography',
  SKILL = 'skill',
}

export const GraphFilterLabelLookup: { [key: string]: string } = {
  [SelectionCategories.COMPANY_FULL]: GraphFilterLabel.COMPANY,
  [SelectionCategories.INDUSTRY_FULL]: GraphFilterLabel.COMPANY,
  [SelectionCategories.COMPANY]: GraphFilterLabel.COMPANY,
  [SelectionCategories.INDUSTRY]: GraphFilterLabel.COMPANY,
  [SelectionCategories.RICS_K10]: GraphFilterLabel.INDUSTRY,
  [SelectionCategories.RICS_K50]: GraphFilterLabel.INDUSTRY,
  [SelectionCategories.JOB_CATEGORY]: GraphFilterLabel.ROLE,
  [SelectionCategories.ROLE_K50]: GraphFilterLabel.ROLE,
  [SelectionCategories.ROLE_K150]: GraphFilterLabel.ROLE,
  [SelectionCategories.ROLE_K500]: GraphFilterLabel.ROLE,
  [SelectionCategories.ROLE_K1500]: GraphFilterLabel.ROLE,
  [SelectionCategories.COUNTRY]: GraphFilterLabel.GEOGRAPHY,
  [SelectionCategories.REGION]: GraphFilterLabel.GEOGRAPHY,
  [SelectionCategories.METRO_AREA]: GraphFilterLabel.GEOGRAPHY,

  [SelectionCategories.SKILL_K75]: GraphFilterLabel.SKILL,
  [SelectionCategories.SKILL_K700]: GraphFilterLabel.SKILL,
  [SelectionCategories.SKILL_K3000]: GraphFilterLabel.SKILL,
};

export const GraphFilterMap: {
  [key: string]: SelectionCategories[];
} = {
  [SelectionCategories.COMPANY_FULL]: [
    SelectionCategories.COMPANY_FULL,
    SelectionCategories.INDUSTRY_FULL,
  ],
};

// Defaults

// default date operator for screener menu
export const defaultOPVal = {
  label: 'is between',
  value: OPValues.BETWEEN,
  isMulti: false,
  filterName: OPValues.BETWEEN,
};

// default date value for screener menu
export const defaultDateVal = {
  label: 'Last Year',
  value: 12,
  filterName: 'Last Year',
};

// default start and end values for screener menu
export const screenerDefaults: {
  [key: string]: { start: number; end: number };
} = {
  [SelectionCategories.HEADCOUNT]: { start: 0, end: 10000 },
  [SelectionCategories.HIRING_RATE]: { start: 0, end: 25 },
  [SelectionCategories.ATTRITION_RATE]: { start: 0, end: 25 },
  [SelectionCategories.GROWTH_RATE]: { start: 0, end: 25 },
  [SelectionCategories.AVERAGE_TENURE]: { start: 0, end: 2 },
  [SelectionCategories.AVERAGE_SALARY]: { start: 0, end: 100000 },
  [SelectionCategories.COUNT]: { start: 0, end: 1000 },
};

/**
 * handles submiting a custom date range filter
 *
 * @param temp
 * @param selectedRef
 * @param tempDate
 *
 * @returns void
 */

/**
 * get start and end values based on state for uperserting filters that
 * require a startValue and endValue.
 *
 * @param selectValue
 * @param startValue
 * @param endValue
 * @param opValue
 *
 * @returns object with startValue and/or endValue based on the selected
 * select value and operator. Otherwise returns an empty object.
 */
export const getStartEndValues = (
  selectValue: ISelectFilter | undefined | null,
  startValue: number | undefined,
  endValue: number | undefined,
  opValue: OPSelectFilter | undefined
): Partial<{ startValue: number; endValue: number }> => {
  if (selectValue === undefined || opValue === undefined) {
    return {};
  }

  const start_value =
    startValue ||
    screenerDefaults[selectValue?.filterName as SelectionCategories].start;
  const end_value =
    endValue ||
    screenerDefaults[selectValue?.filterName as SelectionCategories].end;

  if (opValue.value === OPValues.LESS) {
    return { endValue: end_value };
  }

  if (opValue.value === OPValues.GREATER) {
    return { startValue: end_value };
  }

  return { startValue: start_value, endValue: end_value };
};

export const getCompaniesWithSubsidiaryRCIDs = async (
  formatted: any,
  fetchSubsidiaries: any
) => {
  return Promise.all(
    formatted?.map(async (curEntity: any) => {
      const hasSubsidiaries = get(curEntity, 'data.hasSubsidiaries', false);
      const rcid = get(curEntity, 'data.rcid');

      if (!hasSubsidiaries) return curEntity;

      const subsidiaries = await fetchSubsidiaries(rcid);

      if (subsidiaries) {
        const subsidiaryRCIDs = subsidiaries.map((sub: any) => sub.rcid);

        return { ...curEntity, subsidiaryRCIDs };
      }

      return curEntity;
    })
  );
};
