import { PipelineType } from '@revelio/data-access';
import { DatasetDateFilter } from '../../../deliverables.model';

export enum DateRangeFilterType {
  None = 'none',
  Daily = 'daily',
  Monthly = 'monthly',
}

export const getPipelineDateRangeFilterType = (pipelineType?: PipelineType) => {
  const PipelineDateRangeFilterTypes: Record<
    PipelineType,
    DateRangeFilterType
  > = {
    [PipelineType.CompanyInfo]: DateRangeFilterType.None,
    [PipelineType.Individual]: DateRangeFilterType.Daily,
    [PipelineType.IndividualReviews]: DateRangeFilterType.Daily,
    [PipelineType.Layoffs]: DateRangeFilterType.Daily,
    [PipelineType.Posting]: DateRangeFilterType.Monthly,
    [PipelineType.PostingsIndividual]: DateRangeFilterType.Daily,
    [PipelineType.ReviewTrends]: DateRangeFilterType.Daily,
    [PipelineType.SentimentScores]: DateRangeFilterType.None,
    [PipelineType.SkillDynam]: DateRangeFilterType.Monthly,
    [PipelineType.Transition]: DateRangeFilterType.Monthly,
    [PipelineType.WfDynam]: DateRangeFilterType.Monthly,
  };
  if (!pipelineType) return DateRangeFilterType.None;
  return PipelineDateRangeFilterTypes[pipelineType] ?? DateRangeFilterType.None;
};

export const labelForPipelineDateRangeFilter = (
  dateRange?: DatasetDateFilter
) => {
  if (!dateRange) {
    return 'No date range selected';
  }
  return `${dateRange?.start_date} - ${dateRange?.end_date}`;
};

export const dateFromMonthOrFullDateString = (dateString: string) => {
  if (!dateString) {
    console.warn('dateString expected for dateFromMonthOrFullDateString()');
    return new Date();
  }
  const [year, month, day] = dateString.split('-').map(Number);

  // Check if `day` is provided, i.e., dateString is in YYYY-MM-DD
  if (day) {
    return new Date(year, month - 1, day); // Month is 0-indexed
  } else {
    return new Date(year, month - 1); // For YYYY-MM, set day to 1 by default
  }
};
