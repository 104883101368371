import { PageTitles, PrimaryFilters, Views } from '@revelio/core';
import {
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  RICS_INDUSTRY_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import { Sentiment } from '../../../sentiment/Sentiment';

export function RoleSentiment() {
  const title = [PageTitles.ROLE, PageTitles.SENTIMENT];

  const primaryViewFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];
  const selectableFilters = [
    {
      filters: RICS_INDUSTRY_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
  ];

  return (
    <Sentiment
      title={title}
      view={Views.SENTIMENT}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_SENTIMENT}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.SENTIMENT}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.SENTIMENT}
      enableGptSummaryReviews={true}
    />
  );
}

export default RoleSentiment;
