import { Tree } from 'react-arborist';
import styles from './TreeSelection.module.css';
import { TreeApi, TreeApiProps, useTreeApi } from './useTreeApi';
import { handleSearch } from './utils';
import {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { DrillMode, Node } from './Node';
import classNames from 'classnames';

export type TreeSelectionComponentProps = {
  treeApi: TreeApi;
  noSearchResults?: ReactNode;
  drillMode?: DrillMode;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export type TreeSelectionApiRequiredProps = Pick<
  TreeApiProps,
  'data' | 'openByDefault'
> &
  TreeSelectionComponentProps;

const TreeSelectionApiRequired = ({
  data,
  treeApi,
  openByDefault = false,
  onClick,
  drillMode,
  disabled,
  noSearchResults = 'No Results',
  className,
  style,
}: TreeSelectionApiRequiredProps) => {
  const {
    treeRef,
    selectedIds,
    indeterminateSelectedIds,
    toggleNodeSelection,
    search,
  } = treeApi;

  const [noResults, setNoResults] = useState(false);
  useEffect(() => {
    const tree = treeRef.current;

    const numVisibleNodes = tree?.visibleNodes.length || 0;
    setNoResults(search.length > 0 && numVisibleNodes === 0);
  }, [search, treeRef]);

  return (
    <div
      className={classNames(styles.treeSelectionContainer, className)}
      style={style}
      onClick={onClick}
    >
      {noResults && (
        <div className={styles.noSearchText}>{noSearchResults}</div>
      )}

      <Tree
        data={data}
        className={styles.tree}
        rowClassName={styles.row}
        {...((!drillMode || drillMode?.isRoot) && { ref: treeRef })}
        searchTerm={search}
        searchMatch={handleSearch}
        openByDefault={openByDefault}
        width="100%"
        height={220}
      >
        {(props) => {
          return (
            <Node
              {...props}
              selected={selectedIds.includes(props.node.id)}
              halfCheck={indeterminateSelectedIds.includes(props.node.id)}
              disabled={disabled}
              onToggle={toggleNodeSelection}
              drillMode={drillMode}
            />
          );
        }}
      </Tree>
    </div>
  );
};

/** TreeSelection Internal
 * treeApi is not provided
 * This is used when the TreeSelection component defines its own treeApi.
 * The treeApi can still be exposed to its parent via a ref */
type TreeSelectionInternalApiProps = TreeApiProps &
  Omit<TreeSelectionComponentProps, 'treeApi'> & { treeApi: undefined };
const TreeSelectionInternalApi = forwardRef(
  (props: TreeSelectionInternalApiProps, ref: Ref<TreeApi | undefined>) => {
    const treeApi = useTreeApi(props);

    useImperativeHandle(ref, () => treeApi);

    return <TreeSelectionApiRequired {...props} treeApi={treeApi} />;
  }
);

/** TreeSelection External
 * treeApi provided
 * This is used when the table api should be controlled externally, and when
 * multiple trees are used in the same component, but share a single treeApi
 * (ex. the PopoutTree) */
type TreeSelectionExternalApiProps = TreeApiProps & TreeSelectionComponentProps;
const TreeSelectionExternalApi = (props: TreeSelectionExternalApiProps) => {
  return <TreeSelectionApiRequired {...props} />;
};

/** Exported TreeSelection
 * takes in props for both internal and external treeApi */
export type TreeSelectionProps =
  | TreeSelectionInternalApiProps
  | TreeSelectionExternalApiProps;
export const TreeSelection = forwardRef(
  (props: TreeSelectionProps, ref: Ref<TreeApi | undefined>) => {
    if (props.treeApi) {
      return <TreeSelectionExternalApi {...props} />;
    } else {
      return <TreeSelectionInternalApi {...props} ref={ref} />;
    }
  }
);
