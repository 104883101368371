import {
  SelectionList,
  SelectionListItem,
  SelectionListItems,
} from '../../../engine/filters.model';

export const validateSelectionLists = (
  lists: SelectionList[]
): SelectionListItems[] =>
  lists.map(
    (list): SelectionListItems => ({
      id: list.id,
      value: list.value.map(
        (item): SelectionListItem => ({ ...item, id: `${item.id}` })
      ),
    })
  );
